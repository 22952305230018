// style RCsocial.net logo in getting started column
.link-footer {
text-align: center;

img {
width: 90%;
margin-bottom: 15px;
}

.logo { display: none; }
#scene { display: inline; }
}
.getting-started > .link-footer {
.logo { display: inline; }
#scene { display: none; }
}
.about .link-footer {
font-size: inherit;
margin-top: 40px;
}
.inspiration {
font-family: serif;
font-style: italic;
}

// selection highlight
::-moz-selection {
color: $white;
background-color: $ui-base-lighter-color;
}
::selection {
color: $white;
background-color: $ui-base-lighter-color;
}
